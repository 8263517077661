import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Styles.
import styles from './quote.module.scss';

const Quote = ({ data }) => {
  const {title, author} = data;
  return useMemo(
    () => (
      <div className={styles.quoteWrapper}>
        <div className={styles.quote}>
          <div className={styles.quoteContainer}>
            {data.title && <h2 className={styles.quoteTitle} dangerouslySetInnerHTML={{__html: `"${title}"`}} />}
            {data.author && <p className={styles.quoteAuthor}>{author}</p>}
          </div>
        </div>
      </div>
    ),
    [data]
  );
};

Quote.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
  }),
};

export default Quote;
