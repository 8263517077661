import React from 'react';
import GatewayComponent from '../gateway-component/gateway-component';

const Gateway = ({ gateway }) => {
  return (
    <GatewayComponent data={gateway} />
  );
};

export default Gateway;
