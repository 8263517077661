import React, { useMemo } from 'react';
import styles from './family-rewards-flyer-gateway-container.module.scss';
import Rewards from '../common/homepage_teasers/rewards';
import Flyer from '../common/homepage_teasers/flyer';
import Gateway from '../common/homepage_teasers/gateway';
import Grocery from '../common/grocery';
import Quote from '../common/quote';

const FamilyRewardsFlyerGatewayContainer = ({ rewards, flyer, gateway, grocery, quote }) => {
  return useMemo(
    () => (
      <div className={styles.familyRewardsFlyerGatewayContainerWrapper}>
        <div className={styles.familyRewardsFlyerGatewayContainer}>
          <Grocery grocery={grocery} />
          <Rewards rewards={rewards} />
          <div className={styles.familyRewardsFlyerGatewayTwoColl}>
            <Flyer flyer={flyer} />
            <Gateway gateway={gateway} />
          </div>
          <Quote data={quote} />
        </div>
      </div>
    ),
    [rewards, flyer, gateway]
  );
};

export default FamilyRewardsFlyerGatewayContainer;
