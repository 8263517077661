import React from 'react';
import GatewayComponent from '../gateway-component/gateway-component';

const Flyer = ({ flyer }) => {
  return (
    <GatewayComponent data={flyer} />
  );
};

export default Flyer;
