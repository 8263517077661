import React from 'react';
import FeaturedImageWithWhiteBox from '../image-white-box/image-white-box';

const Rewards = ({ rewards }) => {
  return (
    <FeaturedImageWithWhiteBox data={rewards} />
  );
};

export default Rewards;
