import React, { useMemo } from 'react';
import { get } from 'lodash';
import Img from 'gatsby-image';
import SiteLink from '../../common/site-link';
import * as PropTypes from 'prop-types';
import styles from './image-feature-area.module.scss';

// Set the needed image to text position.
// Available boolean values: 'true', 'false'. Default value is 'false'.
const ImageFeatureArea = ({ data, imageFluid, invert = 'false', background = 'white' }) => {
  const image = get(imageFluid, 'childImageSharp.fluid', get(imageFluid, 'fluid', null));
  const backgroundClass =
    background === 'white' ? styles.imageFeatureAreaWhite : styles.imageFeatureAreaTrans;
  return useMemo(
    () => (
      <div className={styles.imageFeatureAreaWrapper} data-inverse={invert}>
        <div className={backgroundClass}>
          <div className={styles.imageFeatureAreaImage}>
            {image !== null && <Img fluid={image}/>}
          </div>
          <div className={styles.imageFeatureAreaContent}>
            {data.label && <div className={styles.imageFeatureAreaLabel}>{data.label}</div>}
            <h3 className={styles.imageFeatureAreaTitle}>{data.title}</h3>
            <div className={styles.imageFeatureAreaText} dangerouslySetInnerHTML={{ __html: data.text}}/>
            {data.link && <SiteLink href={data.link.uri}>{data.link.title}</SiteLink>}
          </div>
        </div>
      </div>
    ),
    [data],
  );
};

ImageFeatureArea.defaultProps = {
  data: null,
};

ImageFeatureArea.propTypes = {
  data: PropTypes.object,
};

export default ImageFeatureArea;
