// Libs
import React from 'react';
import { graphql } from 'gatsby';
// Deps
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import FamilyRewardsFlyerGatewayContainer from '../components/family-standards/family-rewards-flyer-gateway-container';
// State
import { formatFieldsValue } from '../libs/formatFromGraph';
import FamilyHero from '../components/family-standards/family-hero';

const Family = ({ data }) => {
  const pageData = formatFieldsValue(data);
  return (
    <Layout>
      <SEO title="Family Standards" keywords={[]} />
      <FamilyHero data={pageData.hero} />
      <FamilyRewardsFlyerGatewayContainer
        grocery={pageData.grocery}
        flyer={pageData.flyer}
        gateway={pageData.gateway}
        rewards={pageData.rewards}
        quote={pageData.quote}
      />
    </Layout>
  );
};

export default Family;

export const query = graphql`
  query {
    hero: configPagesFamilyStandards {
      id
      label
      nid: drupal_id
      relationships {
        image: field_family_hero_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 180) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      description: field_fa {
        value
      }
      title: field_family_hero_title {
        value
      }
      image_description: field_family_hero_image_descript {
        value
      }
    }
    quote: configPagesFamilyStandards {
      title: field_quote {
        value
      }
      author: field_quote_author {
        value
      }
    }
    grocery: configPagesFamilyStandards {
      title: field_grocery_gateway_title {
        value
      }
      text: field_grocery_gateway_text {
        value
      }
      relationships {
        image: field_grocery_gateway_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 570) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    rewards: configPagesFamilyStandards {
      title: field_rewards_feature_title {
        value
      }
      text: field_rewards_feature_text {
        value
      }
      relationships {
        image: field_rewards_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    flyer: configPagesFamilyStandards {
      title: field_flyer_feature_title {
        value
      }
      text: field_flyer_feature_text {
        value
      }
      relationships {
        image: field_flyer_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    gateway: configPagesFamilyStandards {
      title: field_gateway_feature_title {
        value
      }
      text: field_gateway_feature_text {
        value
      }
      relationships {
        image: field_gateway_feature_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 555) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
