// Libs
import React, { useMemo } from 'react';
// Deps
import ImageFeatureArea from '../common/image-feature-area/image-feature-area';
// Assets
import styles from './grocery.module.scss';

const Grocery = ({ grocery, className = '' }) => {
  const imageFluid = grocery.image;

  return useMemo(
    () => (
      <div className={className || styles.groceryWrapper}>
        <div className={styles.grocery}>
          <ImageFeatureArea data={grocery} imageFluid={imageFluid}/>
        </div>
      </div>
    ),
    [grocery],
  );
};

export default Grocery;
