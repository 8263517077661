import React, { useMemo } from 'react';
import styles from './family-hero.module.scss';
import Img from 'gatsby-image';

const FamilyHero = ({ data }) => {
  return useMemo(
    () => (
      <div className={styles.familyHeroWrapper}>
        <div className={styles.familyHeroBg}></div>
        <div className={styles.familyHeroContainer}>
          <div className={styles.familyHero}>
            <div className={styles.familyHeroContent}>
              <h1 className={styles.familyHeroTitle}>{data.title}</h1>
              <div className={styles.familyHeroDescription}>{data.description}</div>
              <div className={styles.familyHeroImage}>
                <Img fluid={data.image.fluid} />
              </div>
              <div className={styles.familyHeroImageDescription}>{data.image_description}</div>
            </div>
          </div>
        </div>
      </div>
    ),
    [data]
  );
};

export default FamilyHero;
